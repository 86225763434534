<template>
    <v-app>
        <!--  style="background: #F4F6FA;" -->
        <NavBar style="max-height: 50px; margin-bottom: 10px" />
        <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-2">
                <SideBar style="max-height: 50px" :pageName="pageName"/>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-10">
                <!-- <div v-if="addAddressView" class="scrollDiv"> -->
                    <div class="flexRowBetween my-6" style="height: 48px;">
                    <!-- border: 1px solid #E0E0E0; -->
                        <span class="commonHomeFont">
                            User Management
                        </span>
                    </div>
                    <!-- <div style="height: 64px"></div> -->
                    <div class="addNewUserInnerClass">
                        <div>
                            <span class="commonHomeFont">
                                Address Details
                            </span>
                        </div>
                        <div class="dashedLineClassInNewUser"></div>
                        <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                            <div style="width:49%;">
                                <div class="mb-2" style="text-align: left">House Number /Building Name<span class="astreikClass">*</span></div>
                                <div>
                                    <v-text-field 
                                    solo flat
                                    background-color="#F4F6FA" 
                                    v-model="buildingName" 
                                    :error-messages="v$.$error && v$.$errors[0].$property === 'buildingName' ? v$.$errors[0].$message: ''"
                                    placeholder="House Number /Building Name" />
                                    <!-- :rules="fullNameRules"  -->
                                </div>
                            </div>
                            <div style="width:49%;">
                                <div class="mb-2" style="text-align: left">Street Name / Locality<span class="astreikClass">*</span></div>
                                <div>
                                    <v-text-field 
                                    solo flat
                                    background-color="#F4F6FA" 
                                    v-model="streetName" 
                                    :error-messages="v$.$error && v$.$errors[0].$property === 'streetName' ? v$.$errors[0].$message: ''"
                                    placeholder="Street Name / Locality" />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                            <div style="width:49%;">
                                <div class="mb-2" style="text-align: left">PinCode<span class="astreikClass">*</span></div>
                                <div>
                                    <v-text-field 
                                    solo flat
                                    background-color="#F4F6FA" 
                                    v-model="pinCode" 
                                    :error-messages="v$.$error && v$.$errors[0].$property === 'pinCode' ? v$.$errors[0].$message: ''"
                                    placeholder="PinCode" />
                                    <!-- :rules="fullNameRules"  -->
                                </div>
                            </div>
                            <div style="width:49%;">
                                <div class="mb-2" style="text-align: left">LandMark<span class="astreikClass">*</span></div>
                                <div>
                                    <v-text-field 
                                    solo flat
                                    background-color="#F4F6FA" 
                                    v-model="landMark" 
                                    :error-messages="v$.$error && v$.$errors[0].$property === 'landMark' ? v$.$errors[0].$message: ''"
                                    placeholder="LandMark" />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                            <div style="width:49%;">
                                <div class="mb-2" style="text-align: left">Country<span class="astreikClass">*</span></div>
                                <div>
                                    <v-text-field
                                    solo flat disabled
                                    background-color="#F4F6FA" 
                                    v-model="country" 
                                    placeholder="Country" />
                                    <!-- <v-autocomplete 
                                        solo flat
                                        placeholder="State" 
                                        background-color="#F4F6FA" 
                                        v-model="country" 
                                        :items="countryList"
                                        item-text="name"
                                        item-value="id"
                                        required
                                        @change="changeState(country)"
                                    /> -->
                                </div>
                            </div>
                            <div style="width:49%;">
                                <div class="mb-2" style="text-align: left">State<span class="astreikClass">*</span></div>
                                <div>
                                    <v-autocomplete 
                                        solo flat
                                        placeholder="State" 
                                        background-color="#F4F6FA" 
                                        v-model="state" 
                                        :items="stateList"
                                        :error-messages="v$.$error && v$.$errors[0].$property === 'state' ? v$.$errors[0].$message: ''"
                                        item-text="name"
                                        item-value="id"
                                        required
                                        @change="changeState(state)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                            <div style="width:49%;">
                                <div class="mb-2" style="text-align: left">Address Type<span class="astreikClass">*</span></div>
                                <div>
                                    <v-radio-group row v-model="radioGroup" :error-messages="v$.$error && v$.$errors[0].$property === 'radioGroup' ? v$.$errors[0].$message: ''">
                                        <v-radio
                                            v-for="n in addressTypes"
                                            :key="n"
                                            
                                            :label="`${n}`"
                                            :value="n"
                                        ></v-radio>
                                    </v-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row" style="width:100%;">
                            <div class="d-flex flex-row" style="width:100%; justify-content: flex-start; align-items: center">
                            </div>
                            <div class="d-flex flex-row" style="width:50%; justify-content: space-between;">
                                <div class="d-flex flex-row" @click="addNewAddress()" style="width:49%;"> <!-- addNewAddressClass -->
                                    <!-- <span class="addNewAddressFontClass">
                                        Add New Address
                                    </span> -->
                                </div>
                                <div class="createNewUserClass" @click="saveAndSendOTP()" style="width:49%;cursor: pointer;">
                                    <span class="createNewUserFontClass">
                                        Save & Send OTP
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="my-4" style="width:99%; max-height: 200px;" :disabled="showOTP">
                        <OTPComponent :userData="userDataParams" :otpValueFromUserPage="otpValueFromUserPage"/>
                    </div>

                    <div v-if="confirmationFlag">
                        <DeleteConfirmationDialog :confirmationFlag="confirmationFlag" :itemType="itemType" @closeDialog="closeDialog" />
                    </div>
                    <div v-if="showMessageDialog">
                        <SuccessDialog :successObject="successObject" @closeSuccessDialog="closeSuccessDialog"/>
                    </div>
                <!-- </div> -->
            </div>
        </div>
    </v-app>
</template>

<script>
import axios from "axios";
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';
import { useVuelidate } from '@vuelidate/core'
import { required, email, numeric, minLength, maxLength } from '@vuelidate/validators';
// import { date_format, withParams } from 'vuelidate/lib/validators';
import NavBar from '../../navbar.vue';
import SideBar from '../../sideBar.vue';
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import OTPComponent from '../../../components/OTPComp.vue';
export default {
setup() {
    return {
        v$: useVuelidate()
    }
},
name: "CreateAddressForNewUser",
components : {
    'NavBar': NavBar,
    'SideBar': SideBar,
    'DeleteConfirmationDialog': DeleteConfirmationDialog,
    'SuccessDialog': SuccessDialog,
    'OTPComponent': OTPComponent,
},
validations () {
    return {
        radioGroup: { required },
        landMark: { required },
        pinCode: { required },
        buildingName: { required },
        streetName: { required },
        state: { required },
        country: { required },
    }
},
watch: {},
computed: {
},
data() {
    return {
        showOTP: false,
        addressTypes: ["Home", "Work", "Others"],
        otpValueFromUserPage:'',
        radioGroup: '',
        landMark:'',
        pinCode: '',
        buildingName: '',
        streetName: '',
        gender: '',
        state:'',
        stateList: [],
        countryList: [],
        country:'MYANMAR',
        addUserView: false,
        addAddressView: false,
        pageName: 'CreateAddressForNewUser',
        fromCreateUserPage: '',
        fullName: '',
        nrcModel: '',
        emailID: '',
        dateOfBirthMenu: '',
        dateOfBirth: '',
        fullNameRules: [],
        mobileNumberRules: [
            v => !!v || 'Mobile Number is required',
            v => (v && v.length <= 11 && v.length >=7) || 'Mobile Number should be between 7 to 11 numbers',
            v => v > 0 || 'Mobile number cannot be negative',
        ],
        successObject: {},
        showMessageDialog: false,
        itemType:'',
        iconObjects: [],
        segmentCacheData: [],
        promotionBanners: [],
        specializations: [],
        showPreviewOnBuilder: false,
        previewOnBuilderProps: {},
        bannerEditData: {},
        editBlockDetails: {},
        confirmationFlag: false,
        getBannerSegmentResponseData: {},
        addHeroBannerDialogFlag: false,
        cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
        editIcon: 'editIcon.svg',
        deleteIcon: 'deleteIcon.svg',
        userExistIcon: 'userExist.svg',
        noUserExistIcon: 'noUserExist.svg',
        retryGrey: 'retryGrey.svg',
        noSegmentImage: 'noSegment.svg',
        addBanner: 'addBanner.svg',
        finalEditFlag: false,
        editHeroBannerDialogFlag: false,
        finalEditValue: {},
        multipleAdds: 'multipleAdds.svg',
        addBlock: 'addBlock.svg',
        previewBanner: 'previewBanner.svg',
        addArticles: 'addArticles.svg',
        isHeroBannerAvailable: false,
        isSegmentAvailable: true,
        editFlag: false,
        showPromotionBanners: false,
        showBlock: false,
        showArticles: false,
        userDataParams: {},
    }
},
methods: {
    async changeState(val) {
        console.log(val)
    },
    async saveAndSendOTP() {
        try{
            const validateValie = await this.v$.$validate();
            console.log(this.v$, validateValie);
            if(validateValie) {
                var deliveryAddress = [];
                deliveryAddress.push({
                    streetName : this.streetName,
                    buildingName : this.buildingName,
                    pinCode : this.pinCode,
                    landMark : this.landMark,
                    country : this.country,
                    state : this.state,
                    addressType : this.radioGroup,
                });
                this.userDataParams['deliveryAddress'] = deliveryAddress;
                console.log('this.userDataParams', this.userDataParams);
                var otpBody = {
                    phoneNumber: this.userDataParams.phoneNumber,
                    email: this.userDataParams.email,
                    newUserFlag: true
                };
            
                let sendOTPResponse = await axios.post(process.env.VUE_APP_BACKEND_URL+ "/sendOTP", otpBody);
                console.log('sendOTPResponse', sendOTPResponse);
                alert('OTP is sent');
                this.otpValueFromUserPage = sendOTPResponse.data.code
            }
        }catch(error){
            console.error("An error occurred:", error);
            alert("Something went wrong.");
        }
    },
    addNewAddress() {},
    async goToAddAddress() {
    },
    closeSuccessDialog() {
        this.showMessageDialog = false;
    },
    scrollToElement() {
        this.$nextTick(() => {
            var scrollContainer = document.querySelector(".scrollDiv");
            console.log('scrollContainer', scrollContainer);
            if(scrollContainer) {
                var scrollHeight = scrollContainer.scrollHeight - 1500;
                console.log('scrollHeight', scrollHeight);
                scrollContainer.scrollTop = scrollHeight;
                console.log('scrollContainer.scrollTop', scrollContainer.scrollTop);
            }
        });
    },
    discardAddBlockDialog() {
        this.showPromotionBanners = false;
        this.showArticles = false;
        this.showBlock = false;
        this.isSegmentAvailable = false;
        this.showCommonDraggableIcon = false;
    },
    refreshPage() {
        window.location.reload();
    },
    closeDialog (valueFromEmit) {
        console.log(valueFromEmit.value);
        if (valueFromEmit.value) {
        } else {
            this.confirmationFlag = false;
        }
    },
},
async mounted() {
    try{
        let getStateCountryIdListResponse = await axios_auth_instance_epharmacy_customer.get('/address/stateCountryList');
        this.stateList = getStateCountryIdListResponse.data.data.listOfState;
        this.countryList = getStateCountryIdListResponse.data.data.listOfCountry;
        this.userDataParams = this.$route.query.params;
        this.addUserView = true;
        this.currentUser = this.$cookies.get("token");
        if (!this.currentUser) {
            this.$router.push({
            name: "AdminLogin",
            });
        } else {
            this.userExistIcon = this.cloudFront + this.userExistIcon;
            this.noUserExistIcon = this.cloudFront + this.noUserExistIcon;
            this.previewBanner = this.cloudFront + this.previewBanner;
            this.retryGrey = this.cloudFront + this.retryGrey;
            this.noSegmentImage = this.cloudFront + this.noSegmentImage;
            this.multipleAdds = this.cloudFront + this.multipleAdds;
            this.addBanner = this.cloudFront + this.addBanner;
            this.addBlock = this.cloudFront + this.addBlock;
            this.addArticles = this.cloudFront + this.addArticles;
            this.draggableIcon =  this.cloudFront + this.draggableIcon;
            this.editIcon = this.cloudFront + this.editIcon;
            this.deleteIcon = this.cloudFront + this.deleteIcon;
        }
    }catch(error){
    console.error("An error occurred:", error);
    alert("Something went wrong.");
}
},
}
</script>

<style>

.scrollDiv {
    background: var(--grays-blue-gray-6, #F4F6FA);
    max-height: 1100px;
    overflow-y: scroll;
}
.draggable-item {
background-color: #f2f2f2;
border: 1px solid #ddd;
padding: 10px;
margin-bottom: 5px;
cursor: grab;
width: 100%;
}

.flexRowBetween {
display: flex;
flex-direction: row;
justify-content: space-between;
}
.commonHomeFont{
height: 34px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
color: #333333;
flex: none;
order: 0;
flex-grow: 0;
}
.addNewUserInnerClass {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    isolation: isolate;
    width: 99%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 12px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.dashedLineClassInNewUser {
height: 0px;
border: 1px dashed #BDBDBD;
flex: none;
align-self: stretch;
flex-grow: 0;
}
.ValidateUserClass {
    display: flex;
    width: 240px;
    height: 48px;
    padding: 8px 56px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #219653;
}
.ValidateUserFontClass {
    color: var(--main-color-white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; 
}
.UserValidationFontClass{
    color: var(--grays-gray-1, #333);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.MobileNumberClass {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 12px 13px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: var(--grays-blue-gray-6, #F4F6FA);
}
.noUserExistClass{
    color: var(--secondary, #EB5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.userExistClass {
    color: var(--success-green, #34C873);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}
.addNewAddressClass {
    display: flex;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    border: 1px solid var(--grays-gray-4, #BDBDBD);
}
.addNewAddressFontClass {
    color: var(--grays-grey-3, #828282);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; 
}
.createNewUserClass {
    display: flex;
    width: 186px;
    height: 48px;
    padding: 8px 56px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: #219653;
}
.createNewUserFontClass {
    color: var(--main-color-white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
.astreikClass {
    color: var(--secondary, #EB5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

</style>